import { useContext } from "react";
import { MyContext } from "./context";
export const useMe = () => {
  return useContext(MyContext);
};
export const useMyRole = () => {
  return useMe()?.data?.role;
};
export const useIsOwner = () => {
  return useMe()?.data?.role === "owner";
};
export const useMyEmail = () => {
  return useMe()?.data?.email;
};
export const useMyId = () => {
  return useMe()?.data?.id;
};
export const useMyOrganization = () => {
  return useMe()?.data?.organization;
};
export const useMyOrganizations = () => {
  return useMe()?.data?.organizations;
};
export const useMySettings = () => {
  return useMe()?.data?.settings;
};
