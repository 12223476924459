import {
  DATE_FORMAT_OPTIONS,
  formatUnixToDateString
} from "../components/shared/utils/utils";
import humanizeDuration from "humanize-duration";
import { IonTooltip } from "../components/shared/IonTooltip/index.js";
import { Text } from "@chakra-ui/react";
import React from "react";
export const USD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});
export const DECIMAL = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2
});
export const getFormattedBudget = (total, used = 0) => {
  const usedFormatted = USD.format(used);
  if (total === null) {
    return {
      usedFormatted,
      remaining: null,
      percentageUsed: 100
    };
  }
  if (total === 0) {
    return {
      remaining: USD.format(0),
      totalFormatted: USD.format(total),
      usedFormatted,
      percentageUsed: 0
    };
  }
  if (total > 0) {
    return {
      remaining: USD.format(total - used),
      totalFormatted: USD.format(total),
      usedFormatted,
      percentageUsed: Math.floor(used / total * 100)
    };
  }
};
export const getLastTimeUsedFormatted = (usedMs) => {
  if (!usedMs) return "Never used";
  const roundedDownUsed = Math.floor(usedMs / 1e3);
  const [date, , timezone] = getIonDateFormat(roundedDownUsed).split(" ");
  const dateString = `${date} ${timezone} (${getHumanizedDate(
    roundedDownUsed
  )} ago)`;
  return `Last used on ${dateString}`;
};
const getHumanizedDate = (dateUnix) => humanizeDuration(Date.now() - new Date(dateUnix * 1e3).getTime()).split(
  ","
)?.[0];
export const getTooltipFormattedDate = (dateUnix) => {
  if (typeof dateUnix !== "number" || dateUnix < 0 || dateUnix > Number.MAX_SAFE_INTEGER) {
    return "";
  }
  return getHumanizedDate(dateUnix);
};
export const getIonDateFormat = (date) => {
  if (typeof date !== "number" || date < 0 || date > Number.MAX_SAFE_INTEGER) {
    return "";
  }
  return `${formatUnixToDateString(
    date,
    DATE_FORMAT_OPTIONS.YMD
  )} ${formatUnixToDateString(date, DATE_FORMAT_OPTIONS.YMDHMS)}`;
};
export const ionFormatFromMilliseconds = (ms) => {
  if (typeof ms !== "number" || ms < 0 || ms > Number.MAX_SAFE_INTEGER) {
    return "";
  }
  const date = new Date(Date.UTC(0, 0, 0, 0, 0, 0, ms));
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};
export const MillisecondTime = ({ time }) => /* @__PURE__ */ React.createElement(IonTooltip, { label: /* @__PURE__ */ React.createElement(TimeWrapper, { time }) }, ionFormatFromMilliseconds(time));
const TimeWrapper = ({ time }) => /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, humanizeDuration(time, {
  units: ["h", "m", "s", "ms"],
  delimiter: " "
}).replace("milliseconds", "ms").replace("millisecond", "ms").replace("seconds", "sec").replace("second", "sec").replace("minutes", "min").replace("minute", "min").replace("hour", "h").replace("hours", "h"));
