import React from "react";
import { getSession } from "../../sessions";
import { client } from "../../api";
import { useMyOrganization } from "../shared/Me/useMe";
import { useApi } from "../../Api/ApiProvider.js";
import { jwtDecode } from "jwt-decode";
const { REACT_APP_SSO_BASE_URL } = window._dynamic_env_;
const PrivateRoute = ({
  component: Component,
  isRestricted = false,
  isLoadingLdContext = true
}) => {
  const organization = useMyOrganization();
  const { onSessionExpire } = useApi();
  const isAllowed = !isRestricted || organization?.match(/^com.ionq/);
  const accessToken = getSession();
  if (accessToken) {
    client.accessToken = accessToken;
    const { isVerified } = jwtDecode(accessToken);
    const unverified = accessToken && !isVerified;
    if (!accessToken || unverified) {
      onSessionExpire();
    }
    if (isAllowed)
      return /* @__PURE__ */ React.createElement(Component, { isLoadingLdContext });
  }
  window.location.href = `${REACT_APP_SSO_BASE_URL}/sign-in`;
};
export default PrivateRoute;
