import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Sidebar } from "../../Sidebar/Sidebar.js";
import { SidebarContext } from "../../../contexts/SidebarProvider.js";
export const PageContainer = ({ children }) => {
  const { isOpen } = React.useContext(SidebarContext);
  return /* @__PURE__ */ React.createElement(Box, { mb: "64px" }, /* @__PURE__ */ React.createElement(Flex, null, isOpen && /* @__PURE__ */ React.createElement(
    Box,
    {
      w: isOpen ? "244px" : 0,
      h: isOpen ? "100vh" : 0,
      display: ["none", "none", "block"]
    },
    /* @__PURE__ */ React.createElement(
      Box,
      {
        w: isOpen ? "244px" : 0,
        top: 12,
        bg: "gray.50",
        position: "fixed"
      },
      /* @__PURE__ */ React.createElement(Sidebar, null)
    )
  ), /* @__PURE__ */ React.createElement(
    Box,
    {
      w: [
        "100vw",
        "100vw",
        isOpen ? "calc(100% - 244px)" : "100vw"
      ]
    },
    children
  )));
};
