import React, { Fragment } from "react";
import {
  Box,
  Button,
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
  Divider,
  Avatar,
  Flex
} from "@chakra-ui/react";
import { ArrowUpDownIcon, CheckIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useApi } from "../../Api/ApiProvider.js";
import {
  useIsOwner,
  useMyOrganization,
  useMyOrganizations
} from "../shared/Me/useMe.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAG } from "../App/featureFlags.js";
function createOrgName(org) {
  return org.name ? `${org.name} Organization` : `Organization-${org.id}`;
}
const SwitchOrganizationRow = ({ org, isCurrent, onAccountSwitch }) => {
  const Content = /* @__PURE__ */ React.createElement(Flex, { gap: "12px", alignItems: "center" }, /* @__PURE__ */ React.createElement(
    Avatar,
    {
      size: "sm",
      name: org.name || org.id,
      src: org.logo,
      borderRadius: 5,
      getInitials: (name) => name.substring(0, 2)
    }
  ), /* @__PURE__ */ React.createElement(
    Text,
    {
      flex: "1",
      noOfLines: 1,
      minW: 180,
      fontWeight: isCurrent ? "500" : "400",
      color: isCurrent ? "gray.600" : "ionDark"
    },
    createOrgName(org)
  ), isCurrent && /* @__PURE__ */ React.createElement(CheckIcon, { color: "gray.600" }));
  return /* @__PURE__ */ React.createElement(Box, { w: "100%", p: "4px 8px", key: org.id }, isCurrent ? Content : /* @__PURE__ */ React.createElement(Link, { tabIndex: "0", onClick: () => onAccountSwitch(org.id) }, Content));
};
export const OrganizationPopover = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { onAccountSwitch } = useApi();
  const flags = useFlags();
  const showBillingEngine = flags[FEATURE_FLAG.BILLING_ENGINE_MVP];
  const showOrgSwitcher = flags[FEATURE_FLAG.MULTI_ORG];
  const isOwner = useIsOwner();
  const organizations = useMyOrganizations();
  const orgId = useMyOrganization();
  const organization = organizations?.find((org) => org.id === orgId);
  if (!organization) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Popover, { isOpen, onClose, placement: "bottom-end" }, /* @__PURE__ */ React.createElement(PopoverTrigger, null, /* @__PURE__ */ React.createElement(Center, { display: ["none", null, "flex-item"], mr: "12px" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: onToggle,
      size: "sm",
      color: "gray.600",
      borderColor: "gray.300",
      borderWidth: "1px"
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", mx: "16px" }, createOrgName(organization)),
    /* @__PURE__ */ React.createElement(ArrowUpDownIcon, null)
  ))), /* @__PURE__ */ React.createElement(PopoverContent, { w: "100%" }, showOrgSwitcher && organizations.length > 1 && /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(PopoverBody, { textStyle: "body-3", color: "ionDark" }, /* @__PURE__ */ React.createElement(Box, { p: "4px 8px" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-2", color: "gray.600" }, "Switch organizations")), /* @__PURE__ */ React.createElement(VStack, { gap: "4px", alignItems: "start" }, organizations.map((org) => /* @__PURE__ */ React.createElement(
    SwitchOrganizationRow,
    {
      key: org.id,
      org,
      isCurrent: org.id === orgId,
      onAccountSwitch
    }
  )))), /* @__PURE__ */ React.createElement(Divider, null)), /* @__PURE__ */ React.createElement(PopoverBody, { textStyle: "body-3", color: "ionDark" }, /* @__PURE__ */ React.createElement(VStack, { gap: "4px", alignItems: "start" }, /* @__PURE__ */ React.createElement(Box, { p: "4px 8px" }, /* @__PURE__ */ React.createElement(
    Link,
    {
      onClick: onClose,
      tabIndex: "0",
      to: `/organization/${orgId}/members`
    },
    "Organization Members"
  )), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, showBillingEngine && /* @__PURE__ */ React.createElement(Box, { p: "4px 8px" }, /* @__PURE__ */ React.createElement(
    Link,
    {
      onClick: onClose,
      tabIndex: "0",
      to: `/organization/${orgId}/spend`
    },
    "Organization Spend"
  )), /* @__PURE__ */ React.createElement(Box, { p: "4px 8px" }, /* @__PURE__ */ React.createElement(
    Link,
    {
      onClick: onClose,
      tabIndex: "0",
      to: `/organization/${orgId}/settings`
    },
    "Organization Settings"
  )))))));
};
