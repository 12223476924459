import React, { useRef, useState } from "react";
import copy from "copy-to-clipboard";
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spacer,
  Text,
  Tooltip,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useIsOwner } from "../shared/Me/useMe";
import { IonTable } from "../shared/IonTable";
import { MODALS } from "../shared/baseModals/constants";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { RowActionPopover } from "../shared/RowActionPopover";
import { PageContainer } from "../shared/PageContainer";
import { LoadingView } from "../shared/IonTable/LoadingView.js";
import { useProjects } from "./hooks/useProjects";
import { CreateProjectModal } from "./modals/CreateProjectModal";
import {
  HeaderBanner,
  HIDE_BANNER,
  HIDE_BANNER_YES_VALUE
} from "./ProjectsHeaderBanner";
import {
  getIonDateFormat,
  getTooltipFormattedDate,
  USD
} from "../../util/format";
import { IonToast } from "../shared/IonToast";
import { useOrganization } from "../shared/Organization/useOrganization";
import projectsWhiteBackground from "../../assets/images/Projects_White_Background.png";
import { IdText } from "../shared/IdText";
import { ArchivedBadge } from "./ArchivedBadge.js";
import { useNavigate } from "react-router-dom";
import { ProjectNameCell } from "../shared/IonTableCells/ProjectNameCell.js";
const ProjectRowActionPopover = ({
  projectId,
  projectName,
  isArchived,
  updateProject
}) => {
  const isOwner = useIsOwner();
  const toast = useToast();
  const toastIdRef = useRef();
  const { open: openArchiveProject, close: closeArchiveProject } = useBaseModal(
    MODALS.archiveProjectConfirmation
  );
  const { open: openUnarchiveProject } = useBaseModal(
    MODALS.unarchiveProjectConfirmation
  );
  const ownerMenuItems = isArchived ? [
    {
      key: "unarchive",
      variant: "DANGER",
      node: "Unarchive project",
      onClick: (e) => {
        e.stopPropagation();
        openUnarchiveProject({
          projectId,
          projectName,
          updateProject
        });
      }
    }
  ] : [
    {
      key: "archive",
      variant: "DANGER",
      node: "Archive project",
      onClick: (e) => {
        e.stopPropagation();
        openArchiveProject({
          projectId,
          projectName,
          updateProject
        });
      }
    }
  ];
  const menuItems = [
    {
      key: "view",
      node: "View project details"
      // default event loads the project page
    },
    {
      key: "copyId",
      node: "Copy project ID to clipboard",
      onClick: (e) => {
        e.stopPropagation();
        copy(projectId);
        closeArchiveProject();
        toastIdRef.current = toast({
          duration: 5e3,
          position: "bottom-left",
          render: () => /* @__PURE__ */ React.createElement(
            IonToast,
            {
              message: "Copied to clipboard",
              status: "success",
              toast,
              toastIdRef
            }
          )
        });
      }
    },
    ...isOwner ? ownerMenuItems : []
  ];
  return /* @__PURE__ */ React.createElement(RowActionPopover, { menuItems });
};
const getDisplayRows = ({ hasAccessToPaidBackend, rawRows, updateProject }) => rawRows.map((rawRow) => {
  const {
    id,
    name,
    usersCount,
    quotaUsageUsd,
    quotaLimitUsd,
    lastJobRunTime,
    isArchived
  } = rawRow;
  const quotaLimitUsdDisplayed = typeof quotaLimitUsd === "number" ? /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark" }, USD.format(quotaLimitUsd)) : /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "gray.400" }, "Unlimited");
  const lastJobRunTimeInUnix = new Date(lastJobRunTime).getTime() / 1e3;
  const lastJobRunTimeDisplayed = lastJobRunTime ? /* @__PURE__ */ React.createElement(
    Tooltip,
    {
      hasArrow: true,
      bg: "gray.900",
      label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
        lastJobRunTimeInUnix
      )} ago`),
      borderRadius: "5px",
      color: "white",
      ml: ["5px", "3px"],
      wordWrap: "break-word",
      maxW: ["280px"],
      p: "2px 8px"
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "body-table-name", w: "180px" }, getIonDateFormat(lastJobRunTimeInUnix))
  ) : /* @__PURE__ */ React.createElement(Text, { textStyle: "body-table-name" }, "No job run");
  return {
    id,
    onRowClick: ({ row, navigate }) => navigate(`/projects/${row.id}`),
    name: /* @__PURE__ */ React.createElement(Flex, null, /* @__PURE__ */ React.createElement(Center, { maxW: "550px" }, /* @__PURE__ */ React.createElement(ProjectNameCell, { name })), isArchived && /* @__PURE__ */ React.createElement(Center, { ml: "8px" }, /* @__PURE__ */ React.createElement(ArchivedBadge, null))),
    usersCount: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark" }, usersCount),
    quotaUsageUsd: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "ionDark" }, USD.format(hasAccessToPaidBackend ? quotaUsageUsd : 0)),
    quotaLimitUsd: hasAccessToPaidBackend ? quotaLimitUsdDisplayed : null,
    lastJobRunTime: lastJobRunTimeDisplayed,
    action: /* @__PURE__ */ React.createElement(
      ProjectRowActionPopover,
      {
        projectId: id,
        projectName: name,
        isArchived,
        updateProject
      }
    )
  };
});
const hideBanner = localStorage.getItem(HIDE_BANNER) === HIDE_BANNER_YES_VALUE;
export const ProjectsList = () => {
  const [bannerHidden, setBannerHidden] = useState(hideBanner);
  const navigate = useNavigate();
  const { open: openCreateProjectModal } = useBaseModal(MODALS.createProject);
  const isOwner = useIsOwner();
  const {
    organization,
    isLoading: isOrganizationLoading,
    error: organizationError
  } = useOrganization();
  const {
    projects,
    error: projectError,
    isLoading: isProjectLoading,
    updateProject,
    createProject
  } = useProjects();
  if (isProjectLoading || isOrganizationLoading)
    return /* @__PURE__ */ React.createElement(PageContainer, null, /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(LoadingView, null)));
  if (projectError || organizationError)
    return /* @__PURE__ */ React.createElement(PageContainer, null, projectError || organizationError);
  const hasAccessToPaidBackend = organization?.hasAccessToPaidBackend ?? false;
  const rows = getDisplayRows({
    hasAccessToPaidBackend,
    rawRows: projects,
    updateProject
  });
  return /* @__PURE__ */ React.createElement(PageContainer, { mb: "64px" }, /* @__PURE__ */ React.createElement(Box, { p: ["16px", null, "24px"] }, !bannerHidden && /* @__PURE__ */ React.createElement(Flex, { mb: 6 }, /* @__PURE__ */ React.createElement(HeaderBanner, { setBannerHidden })), /* @__PURE__ */ React.createElement(Flex, { gap: "12px" }, /* @__PURE__ */ React.createElement(Center, { height: "38px" }, /* @__PURE__ */ React.createElement(Text, { color: "ionDark", textStyle: "heading-3" }, "Projects")), /* @__PURE__ */ React.createElement(Spacer, null), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "blue",
      onClick: openCreateProjectModal,
      size: "lg",
      mb: "12px"
    },
    "Create project"
  ), /* @__PURE__ */ React.createElement(CreateProjectModal, { createProject }))), /* @__PURE__ */ React.createElement(
    IonTable,
    {
      label: "Project",
      rows,
      emptyView: /* @__PURE__ */ React.createElement(
        ProjectsEmptyView,
        {
          openCreateProjectModal
        }
      ),
      keyLabels: [
        {
          key: "name",
          label: "Project name"
        },
        {
          key: "id",
          label: "Project ID",
          isCopyable: true,
          getDisplay: (id) => /* @__PURE__ */ React.createElement(IdText, { id })
        },
        {
          key: "usersCount",
          label: "Members"
        },
        {
          key: "quotaUsageUsd",
          label: "Total cost"
        },
        hasAccessToPaidBackend && {
          key: "quotaLimitUsd",
          label: "Budget"
        },
        {
          key: "lastJobRunTime",
          label: "Last job run"
        },
        { key: "action", label: "" }
      ].filter(Boolean),
      onRowClick: (row) => row.onRowClick({ row, navigate })
    }
  )));
};
const ProjectsEmptyView = ({ openCreateProjectModal }) => {
  return /* @__PURE__ */ React.createElement(VStack, { w: "458px", ml: "auto", mr: "auto" }, /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "heading-2",
      color: "gray.900",
      textAlign: "center",
      mb: "20px",
      mt: "40px"
    },
    "All of your projects in one place"
  ), /* @__PURE__ */ React.createElement(Image, { src: projectsWhiteBackground, w: 300, mb: "30px" }), /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-3",
      color: "gray.900",
      textAlign: "center",
      mb: "30px"
    },
    "The cloud console shows you a list of all projects in your organization, but it looks like you haven\u2019t been invited to/created any yet!"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "blue",
      onClick: openCreateProjectModal,
      mb: "40px"
    },
    "Create project"
  ));
};
