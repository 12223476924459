import React, { useRef } from "react";
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Select,
  Spinner,
  Text,
  useToast
} from "@chakra-ui/react";
import { IonTable } from "../shared/IonTable";
import { RowActionPopover } from "../shared/RowActionPopover";
import { useIsOwner, useMe, useMyOrganization } from "../shared/Me/useMe";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
import { ChangeUserRoleModal } from "../Projects/modals/ChangeUserRoleModal";
import { getDisplayUserName } from "../Projects/modals/AddNewProjectMemberModal";
import { AddNewOrganizationMemberModal } from "../Projects/modals/AddNewOrganizationMemberModal";
import { useOrgMembers } from "../Projects/hooks/useOrgMembers";
import { errorToast, successToast } from "../shared/IonToast/IonToast";
import { getStandardError } from "../shared/utils/utils";
import { MemberProjectsModal } from "../Projects/modals/MemberProjectsModal";
import { useProjects } from "../Projects/hooks/useProjects";
import { useOrgInvites } from "../Projects/hooks/useOrgInvites";
import { RemoveUserFromOrganizationModal } from "../Projects/modals/RemoveUserFromOrganizationModal";
import { useNavigate } from "react-router-dom";
import { getIonDateFormat, getTooltipFormattedDate } from "../../util/format.js";
const MEMBER_VIEW_MEMBERS_TABLE_KEY_LABELS = [
  { key: "member", label: "Member" },
  {
    key: "status",
    label: "Status"
  },
  { key: "roles", label: "Role" }
];
const OWNER_VIEW_MEMBERS_TABLE_KEY_LABELS = [
  ...MEMBER_VIEW_MEMBERS_TABLE_KEY_LABELS,
  { key: "projects", label: "Projects" },
  { key: "action", label: "" }
];
const MemberSettingsRowActionPopover = ({ id, userId, projects, userName }) => {
  const { open: openMemberProjectsModal } = useBaseModal(MODALS.memberProjects);
  const { open: openRemoveUserFromOrganization } = useBaseModal(
    MODALS.removeUserFromOrganization
  );
  const menuItems = [
    {
      key: "view",
      node: "View all projects",
      onClick: () => {
        openMemberProjectsModal({ projects, userName });
      }
    }
  ];
  if (id !== userId) {
    menuItems.push({
      key: "delete",
      node: "Delete from organization",
      variant: "DANGER",
      onClick: () => {
        openRemoveUserFromOrganization({
          name: userName,
          id
        });
      }
    });
  }
  return /* @__PURE__ */ React.createElement(RowActionPopover, { menuItems });
};
const InviteRowActionPopover = ({ id, removeInvite, resendInvite }) => {
  const toast = useToast();
  const toastIdRef = useRef();
  const menuItems = [
    {
      key: "resend",
      node: "Resend invite",
      onClick: () => {
        resendInvite({
          userId: id,
          onResendSuccess: successToast({
            message: "Invite successfully resent",
            status: "success",
            toastIdRef,
            toast
          }),
          onResendError: errorToast({ toastIdRef, toast })
        });
      }
    },
    {
      key: "revoke",
      node: "Revoke invite",
      variant: "DANGER",
      onClick: () => {
        removeInvite({
          userId: id,
          onRemoveSuccess: successToast({
            message: "Invite successfully revoked",
            status: "success",
            toastIdRef,
            toast
          }),
          onRemoveError: errorToast({ toastIdRef, toast })
        });
      }
    }
  ];
  return /* @__PURE__ */ React.createElement(RowActionPopover, { menuItems });
};
export const MemberSettings = () => {
  const { open: openChangeUserRole } = useBaseModal(MODALS.changeUserRole);
  const { data: user } = useMe();
  const isOwner = useIsOwner();
  const organization = useMyOrganization();
  const navigate = useNavigate();
  const {
    orgMembers,
    isLoading: isMembersLoading,
    error: membersError,
    removeOrgMember
  } = useOrgMembers();
  const {
    removeInvite,
    resendInvite,
    inviteOrgMembers,
    orgInvites,
    isLoading: isInvitesLoading,
    error: invitesError
  } = useOrgInvites({ isOwner });
  const { open: openAddNewOrganizationMember } = useBaseModal(
    MODALS.addNewOrganizationMember
  );
  const { projects, isProjectLoading, error: projectError } = useProjects();
  if (membersError || isOwner && invitesError || projectError) {
    return /* @__PURE__ */ React.createElement(
      Box,
      {
        borderRadius: "5px",
        bg: "white",
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        border: "1px solid #e8e8e8",
        borderBottom: "2px solid #e8e8e8",
        borderColor: "gray.200"
      },
      /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-8", color: "uiRed", p: "16px" }, getStandardError(membersError)))
    );
  }
  if (organization === "com.quantumbasel" && !isOwner) {
    navigate("/404");
  }
  if (isMembersLoading || isOwner && isInvitesLoading || isProjectLoading) {
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, null));
  }
  const valuesToCells = (item, { isInviteAccepted }) => {
    const member = orgMembers.find((member2) => member2.id === item.id) ?? {};
    const memberProjects = member?.projectIds?.map((y) => projects?.find((x) => x.id === y))?.filter(Boolean) ?? [];
    const userName = getDisplayUserName(member ?? {});
    const expires = item.expires_at ? new Date(item.expires_at).getTime() : null;
    return {
      projects: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", noOfLines: 1, color: "ionDark" }, item.projectIds?.length ?? 0),
      roles: /* @__PURE__ */ React.createElement(React.Fragment, null, item.role && isOwner && isInviteAccepted ? /* @__PURE__ */ React.createElement(
        Select,
        {
          size: "sm",
          height: "28px",
          borderRadius: "5px",
          color: "ionDark",
          borderColor: "gray.200",
          value: item.role,
          onChange: (e) => {
            openChangeUserRole({
              name: getDisplayUserName(item),
              role: e.target.value,
              id: item.id
            });
          },
          _focus: { borderColor: "uiBlue" },
          _active: { borderColor: "uiBlue" },
          textStyle: "body-3",
          w: "90px",
          isDisabled: item.id === user.id
        },
        /* @__PURE__ */ React.createElement("option", { value: "owner" }, "Owner"),
        /* @__PURE__ */ React.createElement("option", { value: "user" }, "User")
      ) : /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", color: "ionDark" }, item.role)),
      action: item.expires_at ? isOwner && /* @__PURE__ */ React.createElement(
        InviteRowActionPopover,
        {
          removeInvite,
          resendInvite,
          id: item.id
        }
      ) : isOwner && /* @__PURE__ */ React.createElement(
        MemberSettingsRowActionPopover,
        {
          projects: memberProjects,
          userName,
          id: item.id,
          userId: user.id
        }
      ),
      member: /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { isTruncated: true, textStyle: "body-3", color: "ionDark" }, `${item.firstName ?? ""} ${item.lastName ?? ""} ${item.id === user.id ? "(You)" : ""}`), /* @__PURE__ */ React.createElement(Text, { isTruncated: true, textStyle: "body-5" }, item.email)),
      status: /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(Text, { isTruncated: true, textStyle: "body-3", color: "ionDark" }, item.expired || item.expires_at ? `Invite ${item.expired ? "expired" : "pending"}` : "Active"), !item.expired && item.expires_at && /* @__PURE__ */ React.createElement(Text, { isTruncated: true }, `Expires in
                            ${getTooltipFormattedDate(expires / 1e3)}
                            (${getIonDateFormat(expires / 1e3)})`)),
      ...item
    };
  };
  const usersSorted = orgMembers.sort((a, b) => getDisplayUserName(a) > getDisplayUserName(b)).map((item) => valuesToCells(item, { isInviteAccepted: true }));
  const invitesMapped = orgInvites.sort((a, b) => a.expires_at < b.expires_at ? 1 : -1).map((item) => valuesToCells(item, { isInviteAccepted: false }));
  const rows = [...invitesMapped, ...usersSorted];
  return /* @__PURE__ */ React.createElement(Box, { mb: "64px" }, /* @__PURE__ */ React.createElement(MemberProjectsModal, null), isOwner && /* @__PURE__ */ React.createElement(ChangeUserRoleModal, null), isOwner && /* @__PURE__ */ React.createElement(
    RemoveUserFromOrganizationModal,
    {
      removeOrgMember
    }
  ), isOwner && /* @__PURE__ */ React.createElement(
    AddNewOrganizationMemberModal,
    {
      inviteOrgMembers
    }
  ), /* @__PURE__ */ React.createElement(
    Flex,
    {
      justifyContent: "space-between",
      mb: "8px",
      direction: ["column", null, "row"]
    },
    /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-3" }, "Organization Members"),
    isOwner && /* @__PURE__ */ React.createElement(
      Button,
      {
        mt: ["8px", null, 0],
        variant: "blue",
        size: "lg",
        onClick: () => openAddNewOrganizationMember()
      },
      "Invite members"
    )
  ), /* @__PURE__ */ React.createElement(
    IonTable,
    {
      label: "Member",
      rows,
      keyLabels: isOwner ? OWNER_VIEW_MEMBERS_TABLE_KEY_LABELS : MEMBER_VIEW_MEMBERS_TABLE_KEY_LABELS
    }
  ));
};
