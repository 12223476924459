import React from "react";
import { Box, Flex, Spacer, Text, VStack } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import {
  MdGridView,
  MdOutlineApi,
  MdOutlineDocumentScanner,
  MdOutlineFolderShared,
  MdOutlineInsertDriveFile,
  MdOutlinePerson,
  MdOutlineSettings
} from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { useMyOrganization } from "../shared/Me/useMe.js";
const SidebarLinkedItem = ({ title, icon, pathname }) => {
  const location = useLocation();
  if (location.pathname === pathname) {
    return /* @__PURE__ */ React.createElement(Link, { to: pathname, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Flex,
      {
        p: "6px",
        w: "100%",
        bg: "gray.100",
        borderRadius: "6px",
        borderColor: "#E0E0E3"
      },
      /* @__PURE__ */ React.createElement(Icon, { as: icon, mt: "2px", mr: "8px", color: "gray.600" }),
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, title),
      /* @__PURE__ */ React.createElement(Spacer, null),
      /* @__PURE__ */ React.createElement(Box, { w: "3px", bg: "#FF8200", borderRadius: "1.5px" })
    ));
  }
  return /* @__PURE__ */ React.createElement(Link, { to: pathname, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Flex, { p: "6px", w: "100%" }, /* @__PURE__ */ React.createElement(
    Icon,
    {
      as: icon,
      mt: "2px",
      mr: "8px",
      textAlign: "left",
      color: "gray.600"
    }
  ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", textDecoration: "none" }, title)));
};
export const Sidebar = () => {
  const organization = useMyOrganization();
  return /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "gray.50",
      color: "gray.800",
      w: "244px",
      borderRight: "1px solid #E0E0E3",
      h: "100vh"
    },
    /* @__PURE__ */ React.createElement(VStack, { p: "16px", gap: 0, borderBottom: "1px solid #E0E0E3" }, /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "My Jobs",
        icon: MdOutlineFolderShared,
        pathname: "/jobs"
      }
    ), /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "Projects",
        icon: MdGridView,
        pathname: "/projects"
      }
    ), /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "Backends",
        icon: MdOutlineApi,
        pathname: "/backends"
      }
    )),
    /* @__PURE__ */ React.createElement(VStack, { p: "16px", gap: 0 }, /* @__PURE__ */ React.createElement(Text, { w: "100%", textStyle: "body-4", px: "8px !important" }, "Settings"), /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "Organization",
        icon: MdOutlineSettings,
        pathname: `/organization/${organization}/members`
      }
    ), /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "Account",
        icon: MdOutlineSettings,
        pathname: `/settings/account`
      }
    ), /* @__PURE__ */ React.createElement(
      SidebarLinkedItem,
      {
        title: "API Keys",
        icon: MdOutlineSettings,
        pathname: `/settings/keys`
      }
    )),
    /* @__PURE__ */ React.createElement(VStack, { m: "0 16px", gap: 0 }, /* @__PURE__ */ React.createElement(Text, { w: "100%", textStyle: "body-4", px: "8px !important" }, "Support"), /* @__PURE__ */ React.createElement(
      Link,
      {
        to: "https://docs.ionq.com/",
        style: { width: "100%" },
        target: "_blank"
      },
      /* @__PURE__ */ React.createElement(Flex, { px: 2, w: "100%" }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineInsertDriveFile,
          mt: "7px",
          mr: "8px",
          color: "gray.600"
        }
      ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", h: "32px", lineHeight: "32px" }, "Documentation"))
    ), /* @__PURE__ */ React.createElement(
      Link,
      {
        to: "https://docs.ionq.com/api-reference/",
        style: { width: "100%" },
        target: "_blank"
      },
      /* @__PURE__ */ React.createElement(Flex, { px: 2, w: "100%" }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlineDocumentScanner,
          mt: "7px",
          mr: "8px",
          color: "gray.600"
        }
      ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", h: "32px", lineHeight: "32px" }, "API Reference"))
    ), /* @__PURE__ */ React.createElement(
      Link,
      {
        to: "https://docs.ionq.com/support/",
        style: { width: "100%" },
        target: "_blank"
      },
      /* @__PURE__ */ React.createElement(Flex, { px: 2, w: "100%" }, /* @__PURE__ */ React.createElement(
        Icon,
        {
          as: MdOutlinePerson,
          mt: "7px",
          mr: "8px",
          color: "gray.600"
        }
      ), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", h: "32px", lineHeight: "32px" }, "Support Requests"))
    ))
  );
};
