import React from "react";
const QLogo = () => {
  return /* @__PURE__ */ React.createElement(
    "svg",
    {
      width: "100",
      height: "26",
      viewBox: "0 0 100 35",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    },
    /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_59_1110)" }, /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M15 4.2L26.4 10.8L30 8.7L15 0L0 8.7L3.6 10.8L15 4.2Z",
        fill: "#D4D4D5"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M3.6001 10.8V23.9L4.8001 23.2L7.3001 21.7999V12.9L15.0001 8.39995V5.49995V4.19995L3.6001 10.8Z",
        fill: "#ED7C37"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M26.4002 10.8V23.9L25.2002 23.2L30.0002 26V8.69995L26.4002 10.8Z",
        fill: "#B7B7B8"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M26.4 10.8L15 4.19995V5.49995V8.39995L22.7 12.9V21.7999L25.2 23.2L26.4 23.9V10.8Z",
        fill: "#DE5126"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M22.7002 26V30.2L26.4002 32.3V28.1L22.7002 26Z",
        fill: "#929293"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M4.8 23.2L3.6 23.9V10.8L0 8.69995V26L15 34.7V30.5L3.6 23.9L4.8 23.2Z",
        fill: "#929293"
      }
    ), /* @__PURE__ */ React.createElement("path", { d: "M15 34.7L22.7 30.2V26L15 30.5V34.7Z", fill: "#B7B7B8" }), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M26.4001 23.9L25.3001 23.3L22.7001 21.8L15.0001 26.3L7.3001 21.8L4.8001 23.2001L3.6001 23.9L15.0001 30.5L22.7001 26L26.4001 28.1L30.0001 26L26.4001 23.9Z",
        fill: "#F89B33"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M29.9999 26L26.3999 28.1V32.3L29.9999 30.2V26Z",
        fill: "#B7B7B8"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M38.6001 25.6V8.59998H42.4001V25.6H38.6001Z",
        fill: "#929293"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M53.5002 8.29995C58.2002 8.19995 62.2002 11.9 62.3002 16.7C62.3002 16.9 62.3002 17 62.3002 17.2C62.3002 22.1 58.3002 26 53.5002 26C48.6002 26 44.7002 22 44.7002 17.2C44.6002 12.4 48.3002 8.49995 53.0002 8.29995C53.1002 8.29995 53.3002 8.29995 53.5002 8.29995ZM53.5002 22.3C56.1002 22.4 58.3002 20.3 58.4002 17.6C58.4002 17.4 58.4002 17.3 58.4002 17.1C58.5002 14.4 56.5002 12.1 53.7002 12C51.0002 11.9 48.7002 13.9 48.6002 16.7C48.6002 16.9 48.6002 17 48.6002 17.2C48.4002 19.8 50.4002 22.1 53.0002 22.3C53.1002 22.3 53.3002 22.3 53.5002 22.3Z",
        fill: "#929293"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M75.3 25.6L68.3 14.4V25.6H64.5V8.59998H69.1L75.5 19V8.69998H79.3V25.7H75.3V25.6Z",
        fill: "#929293"
      }
    ), /* @__PURE__ */ React.createElement(
      "path",
      {
        d: "M90.3 8.29995C95 8.19995 99 11.9 99.1 16.7C99.1 16.9 99.1 17 99.1 17.2C99.1 19.3 98.5 21.3 97.2 22.9L99.3 25.2L96.8 27.3L94.7 25C93.3 25.7 91.8 26.1 90.3 26.1C85.6 26.2 81.6 22.5 81.5 17.8C81.5 17.6 81.5 17.5 81.5 17.3C81.4 12.5 85.1 8.59995 89.8 8.39995C90 8.29995 90.2 8.29995 90.3 8.29995ZM90.3 22.3C90.9 22.3 91.5 22.2 92 22L89.5 19.3L92 17.2L94.5 19.9C94.9 19.1 95.2 18.1 95.1 17.2C95.2 14.5 93.2 12.2 90.4 12.1C87.6 12 85.4 14 85.3 16.8C85.3 17 85.3 17.1 85.3 17.3C85.1 19.9 87.1 22.2 89.7 22.4C90 22.3 90.2 22.3 90.3 22.3Z",
        fill: "#929293"
      }
    )),
    /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_59_1110" }, /* @__PURE__ */ React.createElement("rect", { width: "100", height: "34.7", fill: "white" })))
  );
};
export default QLogo;
