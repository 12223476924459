import React from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import AccountSettings from "./AccountSettings";
import { useIsOwner, useMe, useMyOrganization } from "../shared/Me/useMe";
import { Box } from "@chakra-ui/react";
import { OrganizationSettings } from "./OrganizationSettings";
import { UsageSettings } from "./UsageSettings";
import { MemberSettings } from "./MemberSettings";
import "./Settings.css";
import { APIKeysForProjectsMembers } from "../APIKeys/APIKeysForProjectsMembers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAG } from "../App/featureFlags.js";
import { PageContainer } from "../shared/PageContainer/index.js";
export const Settings = () => {
  const { isLoading } = useMe();
  const organization = useMyOrganization();
  const isOwner = useIsOwner();
  const flags = useFlags();
  const showBillingEngine = flags[FEATURE_FLAG.BILLING_ENGINE_MVP];
  if (isLoading) return null;
  return /* @__PURE__ */ React.createElement(PageContainer, null, /* @__PURE__ */ React.createElement(Box, { p: ["16px", null, "24px"] }, /* @__PURE__ */ React.createElement("div", { className: "Settings" }, /* @__PURE__ */ React.createElement("div", { className: "settings-navigation-container" }, /* @__PURE__ */ React.createElement("div", { className: "settings-navigation" }, /* @__PURE__ */ React.createElement(
    NavLink,
    {
      className: "settings-navigation-link",
      to: "/settings/account"
    },
    "Account Settings"
  ), /* @__PURE__ */ React.createElement(
    NavLink,
    {
      className: "settings-navigation-link",
      to: "/settings/keys"
    },
    "API Keys"
  )), (organization !== "com.quantumbasel" || organization === "com.quantumbasel" && isOwner) && /* @__PURE__ */ React.createElement(
    Box,
    {
      mt: "16px",
      className: "settings-navigation-container"
    },
    /* @__PURE__ */ React.createElement(Box, { className: "settings-navigation" }, /* @__PURE__ */ React.createElement(
      NavLink,
      {
        className: "settings-navigation-link",
        to: `/organization/${organization}/members`
      },
      "Organization Members"
    ), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, showBillingEngine && /* @__PURE__ */ React.createElement(
      NavLink,
      {
        className: "settings-navigation-link",
        to: `/organization/${organization}/spend`
      },
      "Organization Spend"
    ), /* @__PURE__ */ React.createElement(
      NavLink,
      {
        className: "settings-navigation-link",
        to: `/organization/${organization}/settings`
      },
      "Organization Settings"
    )))
  )), /* @__PURE__ */ React.createElement("div", { className: "settings-panels-container" }, /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/account",
      element: /* @__PURE__ */ React.createElement(AccountSettings, null)
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/keys",
      element: /* @__PURE__ */ React.createElement(APIKeysForProjectsMembers, null)
    }
  ), /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/members",
      element: /* @__PURE__ */ React.createElement(MemberSettings, null)
    }
  ), isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/settings",
      element: /* @__PURE__ */ React.createElement(OrganizationSettings, null)
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/spend",
      element: /* @__PURE__ */ React.createElement(UsageSettings, null)
    }
  ))))))));
};
