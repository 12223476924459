import React, { useRef } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Tooltip,
  useToast,
  VStack
} from "@chakra-ui/react";
import { APIKeyCreatedModal } from "./APIKeyCreatedModal";
import { Text } from "@chakra-ui/react";
import { pageAnalytics } from "../../util/analytics";
import { useApiKeys } from "../Projects/hooks/useApiKeys";
import { GenerateAPIKeyModal } from "../Projects/modals/GenerateAPIKeyModal";
import { useBaseModal } from "../shared/baseModals/useBaseModal";
import { MODALS } from "../shared/baseModals/constants";
import {
  getLastTimeUsedFormatted,
  getTooltipFormattedDate
} from "../../util/format";
import { ConfirmApiKeyDeletionModal } from "../Projects/modals/ConfirmApiKeyDeletionModal";
import { IonToast } from "../shared/IonToast";
import { useProjects } from "../Projects/hooks/useProjects";
import { ArchivedBadge } from "../Projects/ArchivedBadge";
import apiKeysWhiteBackground from "../../assets/images/API_Keys_White_Background.png";
export const getProjectFromId = ({ projects, projectId }) => {
  if (!projects) return null;
  const project = projects.find((project2) => project2.id === projectId);
  if (!project) return null;
  return project;
};
const ProjectDisplayName = ({ projects, projectId }) => {
  const project = getProjectFromId({ projects, projectId });
  const { isArchived, name } = project || {};
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(HStack, null, /* @__PURE__ */ React.createElement(Text, { color: "ionDark", textStyle: "body-5", mb: "4px" }, name), isArchived ? /* @__PURE__ */ React.createElement(ArchivedBadge, null) : null));
};
export const APIKeysForProjectsMembers = () => {
  const {
    apiKeys,
    error,
    isLoading: isApiKeysLoading,
    addApiKey,
    removeApiKey
  } = useApiKeys();
  const { projects, isLoading: isProjectsLoading } = useProjects();
  const isLoading = isApiKeysLoading || isProjectsLoading;
  const toast = useToast();
  const toastIdRef = useRef();
  const { open: openGenerateApiKeyModal } = useBaseModal(MODALS.generateApiKey);
  const {
    open: openConfirmApiKeyDeletionModal,
    close: closeConfirmApiKeyDeletionModal
  } = useBaseModal(MODALS.confirmApiKeyDeletion);
  const confirmDeletion = (id) => {
    openConfirmApiKeyDeletionModal({
      apiKey: id,
      revokeKey: () => {
        removeApiKey({
          apiKey: id,
          onRemoveSuccess: () => {
            toastIdRef.current = toast({
              duration: 5e3,
              position: "bottom-left",
              render: () => /* @__PURE__ */ React.createElement(
                IonToast,
                {
                  message: `API key successfully revoked`,
                  status: "success",
                  toast,
                  toastIdRef
                }
              )
            });
            closeConfirmApiKeyDeletionModal();
          },
          onRemoveError: closeConfirmApiKeyDeletionModal
        });
      }
    });
  };
  const sortedKeys = (apiKeys || []).sort((a, b) => {
    if (a.used === void 0 && b.used === void 0) return 0;
    if (a.used === void 0) return 1;
    if (b.used === void 0) return -1;
    return b.used - a.used;
  });
  return /* @__PURE__ */ React.createElement(Box, { id: "APIKeys" }, /* @__PURE__ */ React.createElement(GenerateAPIKeyModal, null), /* @__PURE__ */ React.createElement(ConfirmApiKeyDeletionModal, null), /* @__PURE__ */ React.createElement(APIKeyCreatedModal, null), /* @__PURE__ */ React.createElement(Flex, { justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Text, { textStyle: "heading-3", color: "ionDark" }, "API Keys")), /* @__PURE__ */ React.createElement(
    Button,
    {
      size: "lg",
      variant: "blue",
      type: "submit",
      onClick: () => openGenerateApiKeyModal({ addApiKey })
    },
    "Generate key"
  )), /* @__PURE__ */ React.createElement(Divider, { mt: "12px", mb: "8px" }), /* @__PURE__ */ React.createElement(
    Text,
    {
      textStyle: "body-3",
      mb: "12px",
      mt: "-4px",
      mr: "0.5em",
      color: "ionDark"
    },
    "Check out our guide to",
    /* @__PURE__ */ React.createElement(
      Link,
      {
        isExternal: true,
        href: "https://docs.ionq.com/guides/managing-api-keys",
        target: "_blank",
        rel: "noopener noreferrer",
        color: "uiBlue",
        onClick: () => pageAnalytics(
          "https://docs.ionq.com/guides/managing-api-keys"
        )
      },
      /* @__PURE__ */ React.createElement(Text, { color: "uiBlue", as: "span", ml: "0.3em" }, "generating and storing API keys.")
    )
  ), /* @__PURE__ */ React.createElement(
    Box,
    {
      bg: "white",
      p: isLoading || error || apiKeys.length === 0 ? "16px 24px" : 0,
      boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.04)",
      border: "1px solid #E8E8E8",
      borderRadius: "5px",
      mb: "24px"
    },
    isLoading && /* @__PURE__ */ React.createElement(Box, { textStyle: "body-8", textAlign: "center" }, /* @__PURE__ */ React.createElement(Spinner, null)),
    !isLoading && error && /* @__PURE__ */ React.createElement(Box, { textStyle: "body-8", textAlign: "center", color: "uiRed" }, error),
    !isLoading && !error && apiKeys.length === 0 && /* @__PURE__ */ React.createElement(VStack, { w: "458px", ml: "auto", mr: "auto" }, /* @__PURE__ */ React.createElement(
      Image,
      {
        src: apiKeysWhiteBackground,
        w: 300,
        mb: "30px",
        mt: "40px"
      }
    ), /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-2",
        color: "gray.900",
        textAlign: "center",
        mb: "4px"
      },
      "All of your API keys in one place."
    ), /* @__PURE__ */ React.createElement(
      Text,
      {
        textStyle: "body-3",
        color: "gray.900",
        textAlign: "center",
        mb: "40px"
      },
      "The cloud console shows you a list of all API Keys tied to your projects allowing you to submit jobs, but it looks like you haven\u2019t generated any yet!"
    )),
    !isLoading && !error && apiKeys.length > 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, sortedKeys.map((apiKey, index) => {
      return /* @__PURE__ */ React.createElement(
        Flex,
        {
          key: apiKey.id,
          p: "16px 24px",
          justifyContent: "space-between",
          borderBottom: index === sortedKeys.length - 1 ? "none" : "1px solid #E9E9EB"
        },
        /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(
          Text,
          {
            color: "ionDark",
            textStyle: "body-2",
            mb: "2px"
          },
          apiKey.name
        ), /* @__PURE__ */ React.createElement(
          ProjectDisplayName,
          {
            projects,
            projectId: apiKey.projectId
          }
        ), /* @__PURE__ */ React.createElement(
          Tooltip,
          {
            hasArrow: true,
            bg: "gray.900",
            label: /* @__PURE__ */ React.createElement(Text, { textStyle: "body-6" }, `${getTooltipFormattedDate(
              apiKey.used
            ) || "0 days"} ago`),
            borderRadius: "5px",
            color: "white",
            ml: ["5px", "3px"],
            wordWrap: "break-word",
            maxW: ["280px"],
            p: "2px 8px"
          },
          /* @__PURE__ */ React.createElement(
            Text,
            {
              textStyle: "body-5",
              color: "gray.600"
            },
            getLastTimeUsedFormatted(
              apiKey.used * 1e3
            )
          )
        )),
        /* @__PURE__ */ React.createElement(Center, { dir: "vertical" }, /* @__PURE__ */ React.createElement(
          Button,
          {
            size: "sm",
            variant: "white",
            color: "uiRed",
            onClick: () => {
              confirmDeletion(apiKey.id);
            }
          },
          "Revoke"
        ))
      );
    }))
  ));
};
