import React from "react";
import { useState, useEffect } from "react";
export const SidebarContext = React.createContext({
  isOpen: true,
  toggleSidebar: () => {
  }
});
export const SidebarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(() => {
    const saved = localStorage.getItem("sidebarOpen");
    const isMobile = window.innerWidth < 666;
    return isMobile ? false : saved !== null ? JSON.parse(saved) : true;
  });
  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
  }, [isOpen]);
  const toggleSidebar = () => setIsOpen(!isOpen);
  return /* @__PURE__ */ React.createElement(SidebarContext.Provider, { value: { isOpen, toggleSidebar } }, children);
};
