import React, { useContext, useState } from "react";
import { hasSession } from "../../sessions";
import { Link } from "react-router-dom";
import "./Header.css";
import QLogo from "../shared/QLogo";
import { HeaderExtendedMenu } from "./HeaderExtendedMenu";
import { SidebarContext } from "../../contexts/SidebarProvider.js";
import { MdMenu } from "react-icons/md";
import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Divider,
  Flex,
  Spacer,
  Text,
  VStack
} from "@chakra-ui/react";
import { useIsOwner, useMyEmail, useMyOrganization } from "../shared/Me/useMe.js";
import { useApi } from "../../Api/ApiProvider.js";
import Gravatar from "react-gravatar";
import { OrganizationPopover } from "../OrganizationPopover/index.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FEATURE_FLAG } from "../App/featureFlags.js";
const { REACT_APP_IONQ_WEBSITE_BASE_URL } = window._dynamic_env_;
export const Header = () => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const email = useMyEmail();
  const isOwner = useIsOwner();
  const organization = useMyOrganization();
  const flags = useFlags();
  const showBillingEngine = flags[FEATURE_FLAG.BILLING_ENGINE_MVP];
  const toggleMobileMenu = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuIsOpen(false);
  };
  const { isOpen } = useContext(SidebarContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const { onSessionExpire } = useApi();
  const logoutUser = () => {
    closeMobileMenu();
    onSessionExpire();
  };
  return hasSession() && /* @__PURE__ */ React.createElement("div", { className: "header-container" }, /* @__PURE__ */ React.createElement("div", { className: "header" }, /* @__PURE__ */ React.createElement(
    Icon,
    {
      p: 1,
      h: 8,
      w: 8,
      mt: "10px",
      ml: "18px",
      as: MdMenu,
      onClick: toggleSidebar,
      "aria-label": "menu",
      cursor: "pointer",
      display: ["none", null, "block"]
    }
  ), /* @__PURE__ */ React.createElement("div", { className: `header-content` }, isOpen && /* @__PURE__ */ React.createElement(
    Box,
    {
      w: "1px",
      h: "100%",
      bg: "#E0E0E3",
      position: "absolute",
      left: 243,
      display: ["none", null, "block"]
    }
  ), /* @__PURE__ */ React.createElement(Link, { to: "/", className: "logo" }, /* @__PURE__ */ React.createElement(QLogo, null)), /* @__PURE__ */ React.createElement(Spacer, null), /* @__PURE__ */ React.createElement(OrganizationPopover, null), /* @__PURE__ */ React.createElement(HeaderExtendedMenu, { closeMobileMenu }), /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `mobile-menu-toggle ${mobileMenuIsOpen && "-close"}`,
      onClick: toggleMobileMenu
    },
    /* @__PURE__ */ React.createElement("span", { className: "lines" })
  ), mobileMenuIsOpen && /* @__PURE__ */ React.createElement(
    VStack,
    {
      position: "fixed",
      top: "52px",
      bottom: 0,
      right: 0,
      left: 0,
      bg: "gray.50",
      display: ["flex", null, "none"],
      alignItems: "start"
    },
    /* @__PURE__ */ React.createElement(Box, { w: "100%", overflowY: "scroll" }, /* @__PURE__ */ React.createElement(
      Box,
      {
        p: "12px 16px",
        onClick: toggleMobileMenu
      },
      /* @__PURE__ */ React.createElement(Link, { to: "/jobs" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-4" }, "My Jobs"))
    ), /* @__PURE__ */ React.createElement(Divider, { m: 0, p: 0 }), /* @__PURE__ */ React.createElement(
      Box,
      {
        p: "12px 16px",
        onClick: toggleMobileMenu
      },
      /* @__PURE__ */ React.createElement(Link, { to: "/backends" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-4" }, "Backends"))
    ), /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(
      Box,
      {
        p: "12px 16px",
        onClick: toggleMobileMenu
      },
      /* @__PURE__ */ React.createElement(Link, { to: "/projects" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-4" }, "Projects"))
    ), /* @__PURE__ */ React.createElement(Divider, { m: 0, p: 0 }), /* @__PURE__ */ React.createElement(
      VStack,
      {
        bg: "gray.700",
        color: "white",
        gap: "4px",
        w: "100%",
        alignItems: "start"
      },
      /* @__PURE__ */ React.createElement(
        Flex,
        {
          gap: 2,
          w: "100%",
          px: "16px",
          mt: "8px"
        },
        /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(
          Box,
          {
            maxH: "34px",
            borderRadius: "5px",
            overflow: "hidden"
          },
          /* @__PURE__ */ React.createElement(
            Gravatar,
            {
              email,
              size: 34,
              rating: "pg",
              default: "retro"
            }
          )
        )),
        /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Signed in as"), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-1" }, email))
      ),
      /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Box,
        {
          tabIndex: "0",
          onClick: logoutUser,
          cursor: "pointer"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Sign out")
      )),
      /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: "/settings/account"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Account Settings")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: "/settings/keys"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "API Keys")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: `/organization/${organization}/members`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Organization Members")
      )),
      isOwner && /* @__PURE__ */ React.createElement(React.Fragment, null, showBillingEngine && /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: `/organization/${organization}/spend`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Organization Spend")
      )), /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: `/organization/${organization}/settings`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Organization Settings")
      ))),
      /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          tabIndex: "0",
          rel: "noreferrer",
          target: "_blank",
          href: "https://docs.ionq.com"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Documentation")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          rel: "noreferrer",
          target: "_blank",
          href: "https://docs.ionq.com/api-reference/v0.3/introduction"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "API Reference")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        Link,
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          to: "/settings/account"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Share Feedback")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        "button",
        {
          tabIndex: "0",
          onClick: (e) => {
            e.preventDefault();
            if (window.appzi !== void 0) {
              window.appzi.openSurvey(
                "ffb8e358-0d74-4a01-b572-47e2fd2bcbc4"
              );
            }
            toggleMobileMenu();
          },
          rel: "noreferrer"
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Support")
      )),
      /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          rel: "noreferrer",
          target: "_blank",
          href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/eula`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "End User License Agreement")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          rel: "noreferrer",
          target: "_blank",
          href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/terms`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Terms of Service")
      )),
      /* @__PURE__ */ React.createElement(Box, { p: "4px 16px 16px" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          tabIndex: "0",
          onClick: toggleMobileMenu,
          rel: "noreferrer",
          target: "_blank",
          href: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/privacy`
        },
        /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Privacy Policy")
      ))
    ))
  ))));
};
