import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import {
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import "./HeaderExtendedMenu.css";
import { pageAnalyticsIdentify } from "../../util/analytics";
import {
  useMe,
  useMyEmail,
  useMyId,
  useMyOrganization,
  useMyRole
} from "../shared/Me/useMe";
import { useApi } from "../../Api/ApiProvider.js";
const { REACT_APP_IONQ_WEBSITE_BASE_URL } = window._dynamic_env_;
export const HeaderExtendedMenu = ({ closeMobileMenu }) => {
  const [extendedMenuIsOpen, setExtendedMenuIsOpen] = useState(false);
  const { onSessionExpire } = useApi();
  const toggleExtendedMenu = () => {
    setExtendedMenuIsOpen(!extendedMenuIsOpen);
  };
  const closeExtendedMenu = () => {
    setExtendedMenuIsOpen(false);
  };
  const logoutUser = () => {
    closeMobileMenu();
    onSessionExpire();
  };
  const email = useMyEmail();
  const role = useMyRole();
  const id = useMyId();
  const user = useMe();
  const organization = useMyOrganization();
  const getDisplayUserName = (user2) => {
    const { firstName, lastName, email: email2 } = user2;
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    const name = firstName || lastName;
    if (name) {
      return `${name}`;
    }
    return email2;
  };
  useEffect(() => {
    pageAnalyticsIdentify({
      id,
      role,
      organization
    });
  }, [id, role, organization]);
  if (!email || !role || !organization)
    return /* @__PURE__ */ React.createElement(AbsoluteCenter, null, /* @__PURE__ */ React.createElement(Spinner, { color: "gray.50" }));
  return /* @__PURE__ */ React.createElement(Popover, { className: "HeaderExtendedMenu" }, /* @__PURE__ */ React.createElement(PopoverTrigger, null, /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement(Box, { mr: "24px" }, /* @__PURE__ */ React.createElement(
    Box,
    {
      "data-testid": "HeaderExtendedMenuToggle",
      onClick: toggleExtendedMenu,
      onKeyDown: toggleExtendedMenu,
      tabIndex: "0",
      borderRadius: "5px",
      overflow: "hidden",
      cursor: "pointer",
      display: ["none", "none", "block"]
    },
    /* @__PURE__ */ React.createElement(
      Gravatar,
      {
        email,
        size: 26,
        rating: "pg",
        default: "retro"
      }
    )
  )))), /* @__PURE__ */ React.createElement(
    PopoverContent,
    {
      mr: "24px",
      boxShadow: "0px 8px 24px 0px rgba(66, 74, 83, 0.12), 0px 1px 3px 0px rgba(27, 31, 36, 0.12);"
    },
    /* @__PURE__ */ React.createElement(PopoverBody, null, /* @__PURE__ */ React.createElement(VStack, { gap: "4px" }, /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-1" }, user?.data && getDisplayUserName(user.data)), /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, email)), /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }), /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        tabIndex: "0",
        onClick: closeExtendedMenu,
        to: "/settings/account"
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Account Settings")
    )), /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }), /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        to: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/eula`
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "End User License Agreement")
    )), /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        to: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/terms`
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Terms of Service")
    )), /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        tabIndex: "0",
        onClick: closeExtendedMenu,
        rel: "noreferrer",
        target: "_blank",
        to: `${REACT_APP_IONQ_WEBSITE_BASE_URL}/privacy`
      },
      /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3" }, "Privacy Policy")
    )), /* @__PURE__ */ React.createElement(Divider, { mt: 1, mb: 1 }), /* @__PURE__ */ React.createElement(Box, { w: "100%" }, /* @__PURE__ */ React.createElement(Box, { tabIndex: "0", onClick: logoutUser }, /* @__PURE__ */ React.createElement(Text, { textStyle: "body-3", color: "red.600" }, "Sign out")))))
  ));
};
