import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useMyEmail } from "../components/shared/Me/useMe";
import { Client } from "../api";
import { Api } from "./Api";
const {
  REACT_APP_API_BASE_URL,
  REACT_APP_SSO_BASE_URL,
  REACT_APP_SSO_CLIENT_ID,
  REACT_APP_WEBSITE_BASE_URL
} = window._dynamic_env_;
const CLOUD_UI_CALLBACK_URI = `${REACT_APP_WEBSITE_BASE_URL}/auth/callback`;
const redirectToSSO = (payload) => {
  const { to, from, ...params } = payload;
  const url = new URL(to, REACT_APP_SSO_BASE_URL);
  const searchParams = url.searchParams;
  const entries = Object.entries(params).filter((kv) => kv[1]);
  entries.forEach((kv) => searchParams.set(...kv));
  searchParams.append("client_id", REACT_APP_SSO_CLIENT_ID);
  searchParams.append("redirect_uri", CLOUD_UI_CALLBACK_URI);
  if (from) searchParams.append("target_uri", new URL(from).pathname);
  window.location.replace(url);
};
const stubbedClient = new Api();
const ApiContext = createContext({
  isLoading: true,
  api: null
});
export const ApiProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiClient, setApiClient] = useState(null);
  const [api, setApi] = useState(stubbedClient);
  const userEmail = useMyEmail();
  const onSessionExpire = useCallback(
    () => redirectToSSO({
      to: "/sign-out",
      from: window.location.href,
      email: userEmail
    }),
    [userEmail]
  );
  useEffect(() => {
    if (!userEmail) return;
    const apiClient2 = new Client(REACT_APP_API_BASE_URL, onSessionExpire);
    setApiClient(apiClient2);
  }, [userEmail, setApiClient, onSessionExpire]);
  const onAccountSwitch = (organization) => {
    redirectToSSO({
      to: "/accounts/switch",
      from: window.location.href,
      organization
    });
  };
  useEffect(() => {
    if (!apiClient) return;
    const api2 = new Api(apiClient);
    setApi(api2);
    setIsLoading(false);
  }, [setApi, apiClient]);
  const value = useMemo(
    () => ({
      isLoading,
      api,
      onSessionExpire,
      onAccountSwitch
    }),
    [onAccountSwitch, onSessionExpire, isLoading, api]
  );
  return /* @__PURE__ */ React.createElement(ApiContext.Provider, { value }, children);
};
export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within a ApiProvider");
  }
  const { api, isLoading, onSessionExpire, onAccountSwitch } = context;
  return { Api: api, isLoading, onSessionExpire, onAccountSwitch };
};
