import React from "react";
import { Form, Field, Formik } from "../../FormikWrapper";
import * as Yup from "yup";
const Profile = ({ user: { firstName, lastName }, onSubmit }) => {
  return /* @__PURE__ */ React.createElement(
    Formik,
    {
      submitAction: onSubmit,
      enableReinitialize: true,
      initialValues: {
        firstName: firstName || "",
        lastName: lastName || ""
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string().required("first name is required"),
        lastName: Yup.string().required("last name is required")
      })
    },
    ({ dirty, touched, setErrors, errors, status, isSubmitting }) => /* @__PURE__ */ React.createElement("div", null, status && status.success && /* @__PURE__ */ React.createElement("div", { className: "success-box" }, status.success), errors.global && /* @__PURE__ */ React.createElement("div", { className: "error-box" }, errors.global), /* @__PURE__ */ React.createElement(Form, { className: "settings-panel" }, /* @__PURE__ */ React.createElement("h5", { className: "settings-panel-internal-headline" }, "Profile"), /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "First name"), /* @__PURE__ */ React.createElement(
      Field,
      {
        onFocus: () => {
          setErrors({
            ...errors,
            firstName: ""
          });
        },
        className: `text-input ${dirty && touched.firstName && errors.firstName ? "-errored" : ""}`,
        type: "text",
        name: "firstName"
      }
    ), dirty && touched.firstName && errors.firstName && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.firstName)), /* @__PURE__ */ React.createElement("div", { className: "settings-panel-input-group" }, /* @__PURE__ */ React.createElement("label", { className: "label" }, "Last name"), /* @__PURE__ */ React.createElement(
      Field,
      {
        onFocus: () => {
          setErrors({
            ...errors,
            lastName: ""
          });
        },
        className: `text-input ${dirty && touched.lastName && errors.lastName ? "-errored" : ""}`,
        type: "text",
        name: "lastName"
      }
    ), dirty && touched.lastName && errors.lastName && /* @__PURE__ */ React.createElement("p", { className: "error-message" }, errors.lastName)), /* @__PURE__ */ React.createElement(
      "button",
      {
        type: "submit",
        disabled: !dirty,
        className: "settings-panel-submit-button"
      },
      isSubmitting ? "Updating profile..." : "Update profile"
    )))
  );
};
export default Profile;
